<template>
  <div class="btn-group btn-group-sm" role="group">
    <button
      @click="$emit('activate', actionIndex)"
      v-for="(action, actionIndex) in actions"
      :key="`key-${actionIndex}`"
      :class="{ active: actionIndex === activeIndex }"
      class="btn btn-outline-primary"
      type="button"
      role="button"
    >{{ action }}</button>
  </div>
</template>

<script>
export default {
  name: 'SwitchButton',
  props: {
    actions: {
      type: Array,
      required: true,
    },
    activeIndex: {
      type: Number,
      default: 0,
    },
  },
};
</script>
